<template>
  <Maintenance />
</template>

<script>
import Maintenance from '@/common/components/Maintenance';

export default {
  name: 'Home',
  components: {
    Maintenance,
  },
};
</script>
